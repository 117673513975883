import React from 'react'
import { Chip } from '@mui/material'
import PropTypes, { array } from 'prop-types';
import { Col } from 'react-bootstrap';
import { default as fields_list } from '../../../data/fields';

function IntroductionSection(props) {
  let changeSection = props.changeSection
  let setLevel = props.setLevel
  let setFields = props.setFields
  let level = props.level
  let fields = props.fields

  function addRemoveField(field) {
    if(fields.includes(field)) {
      // removing item
      let index = fields.indexOf(field)
      fields.splice(index, 1)
      setFields([...fields])
    } else {
      // adding field
      fields.push(field)
      setFields([...fields])
    }
  }

  function onClick() {
    if(level != -1 && fields.length != 0) changeSection()
  }

  return (
    <div>
      <br/>
      <br/>
      <h2>Iniziamo con qualche domanda introduttiva</h2>
      <br/>
      <p>A che livello ti ritieni?</p>
      <Chip
      onClick={() => setLevel(1)}
      className={"my_chip" + (level == 1 ? " selected" : "")}
      label={"Base"} />
      <Chip
      onClick={() => setLevel(2)}
      className={"my_chip" + (level == 2 ? " selected" : "")}
      label={"Medio"} />
      <Chip
      onClick={() => setLevel(3)}
      className={"my_chip" + (level == 3 ? " selected" : "")}
      label={"Alto"} />
      <br/>
      <br/>
      <p>Quali argomenti vuoi approfondire?</p>
      <Col md="8" className="mx-auto">
        {
          fields_list.map(field => (
          <Chip
          onClick={() => addRemoveField(field)}
          className={"my_chip" + (fields.includes(field) ? " selected" : "")}
          label={field} /> 
          ))
        }
      </Col>
      <br/>
      <br/>
      <button onClick={onClick}>Inizia il quiz</button>
    </div>
  )
}

IntroductionSection.propTypes = {
  changeSection: PropTypes.func.isRequired,
  level: PropTypes.number.isRequired,
  fields: PropTypes.array.isRequired,
  setFields: PropTypes.func.isRequired,
  setLevel: PropTypes.func.isRequired
}

export default IntroductionSection