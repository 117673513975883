const questions = {
  "Azioni" : {
    1 : [
      {
         "title":"Che cos’è un’azione finanziaria?",
         "choices":[
            "Un diritto che chiunque puó esercitare",
            "Una porzione di società",
            "L’insieme dei dipendenti di una società",
            "Un prezzo variabile"
         ],
         "correctChoice":"Una porzione di società"
      },
      {
         "title":"Le azioni danno diritto a:",
         "choices":[
            "Dividendi",
            "Interessi sul prestito di capitale",
            "Rimborso del capitale",
            "Possibilità di conversione in opzioni"
         ],
         "correctChoice":"Dividendi"
      },
      {
         "title":"La percentuale massima di capitale che rischio di perdere con le azioni è del:",
         "choices":[
            " 20%",
            " 40%",
            " 80%",
            "100%"
         ],
         "correctChoice":"100%"
      },
      {
         "title":"La percentuale massima di capitale che posso guadagnare con le azioni è del:",
         "choices":[
            "20%",
            "40%",
            "100%",
            "Un guadagno infinito"
         ],
         "correctChoice":"Un guadagno infinito"
      },
      {
         "title":"Con la sigla EPS si intende:",
         "choices":[
            "L’utile totale della società",
            "Il fatturato totale della società",
            "L’utile per azione",
            "Il fatturato per azione"
         ],
         "correctChoice":"L’utile per azione"
      },
      {
         "title":"Con il rapporto P/E calcoliamo:",
         "choices":[
            "Il prezzo che paghiamo per gli utili societari",
            "Il prezzo che siamo disposti a pagare per un’azione",
            "Il prezzo di vendita dell’azione sul mercato ",
            "Il prezzo giusto per una singola azione "
         ],
         "correctChoice":"Il prezzo che paghiamo per gli utili societari"
      }
    ],
    2 : [
      {
         "title":"Una società per azioni può acquistare azioni proprie?",
         "choices":[
            "No, mai",
            "Si, ma massimo ogni 2 anni",
            "Si, ma con vincoli normativi",
            "Si, sempre"
         ],
         "correctChoice":"Si, ma con vincoli normativi"
      },
      {
         "title":"Quand’è che un azionista possiede diritto di voto?",
         "choices":[
            "Mai",
            "Quando possiede azioni ordinarie",
            "Quando possiede azioni privilegiate",
            "Sempre"
         ],
         "correctChoice":"Quando possiede azioni ordinarie"
      },
      {
         "title":"Cosa succede quando una società diluisce il capitale?",
         "choices":[
            "Il prezzo delle azioni scende",
            "Il prezzo delle azioni sale",
            "Vengono date nuove azioni ai soci",
            "Vengono sottratte alcune azioni ai soci"
         ],
         "correctChoice":"Il prezzo delle azioni scende"
      },
      {
         "title":"Se una società stacca il dividendo, cosa succede al prezzo delle azioni?",
         "choices":[
            "Sale, in proporzione alla percentuale della cedola",
            "Scende, in proporzione alla percentuale della cedola",
            "Rimane invariato",
            "Aumenta la sua volatilità"
         ],
         "correctChoice":"Scende, in proporzione alla percentuale della cedola"
      },
      {
         "title":"Con il PEG calcoliamo:",
         "choices":[
            "Il prezzo in relazione agli utili e al debito societario",
            "Il prezzo in relazione agli utili e al credito societario",
            "Il prezzo in relazione agli utili e alle aspettative di crescita",
            "II prezzo in relazione agli utili e al fatturato"
         ],
         "correctChoice":"Il prezzo in relazione agli utili e alle aspettative di crescita"
      },
      {
         "title":"Cosa indica la sigla EBIT?",
         "choices":[
            "Gli utili registrati prima del tasso di crescita",
            "Gli utili registrati prima del pagamento del dividendo",
            "Gli utili registrati prima delle stime degli analisti",
            "Gli utili registrati prima degli oneri finanziari "
         ],
         "correctChoice":"Gli utili registrati prima degli oneri finanziari "
      }
    ],
    3 : [
      {
         "title":"Se il mio investimento accusa una perdita del 90%, per recuperare avrò bisogno del:",
         "choices":[
            " 9%",
            " 89%",
            " 90%",
            " 900%"
         ],
         "correctChoice":" 900%"
      },
      {
         "title":"Quale indice misura la deviazione media di un titolo?",
         "choices":[
            "Alfa",
            "Beta",
            "Gamma",
            "Omega"
         ],
         "correctChoice":"Beta"
      },
      {
         "title":"Quando una società diluisce il capitale:",
         "choices":[
            "Il prezzo delle azioni scende",
            "Vengono date nuove azioni ai soci",
            "Il prezzo delle azioni sale",
            "Vengono sottratte delle azioni ai soci"
         ],
         "correctChoice":"Il prezzo delle azioni scende"
      },
      {
         "title":"Se una società stacca il dividendo, cosa succede al prezzo delle azioni?",
         "choices":[
            "Sale, in proporzione alla percentuale della cedola",
            "Scende, in proporzione alla percentuale della cedola",
            "Rimane invariato",
            "Aumenta la sua volatilità"
         ],
         "correctChoice":"Scende, in proporzione alla percentuale della cedola"
      },
      {
         "title":"Il capitale sociale è:",
         "choices":[
            "Il patrimonio dell’azienda",
            "La somma del valore dei beni immobili della società",
            "La somma della liquidità societaria e delle attività correnti",
            "Il valore dei conferimenti dei soci"
         ],
         "correctChoice":"Il valore dei conferimenti dei soci"
      },
      {
         "title":"Che cos’è la modalità di riduzione del capitale sociale?",
         "choices":[
            "Una divisione degli utili",
            "Un piano di emissione di obbligazioni",
            "Un piano di utilizzo delle riserve disponibili",
            "Un piano di emissione di nuove azioni "
         ],
         "correctChoice":"Un piano di utilizzo delle riserve disponibili"
      }
    ]
  },
  "Obbligazioni" : {
    1 : [
      {
         "title":"Cos’è un’obbligazione?",
         "choices":[
            "Una quota di capitale sociale",
            "Un titolo di debito",
            "Un titolo di credito",
            "Una quota di conferimento al patrimonio netto"
         ],
         "correctChoice":"Un titolo di debito"
      },
      {
         "title":"Le obbligazioni danno diritto a:",
         "choices":[
            "Dividendi",
            "Interessi sul prestito obbligazionario",
            "Rimborso del capitale",
            "Possibilità di conversione in opzioni"
         ],
         "correctChoice":"Interessi sul prestito obbligazionario"
      },
      {
         "title":"Le obbligazioni emesse da enti governativi sono definite:",
         "choices":[
            "Titoli speciali governativi",
            "Titoli di stato",
            "Titoli garantiti nazionali",
            "Patrimonio statale"
         ],
         "correctChoice":"Titoli di stato"
      },
      {
         "title":"Come si chiamano le obbligazioni governative italiane a lungo termine?",
         "choices":[
            "Bot",
            "Bund",
            "Btp",
            "Bonos"
         ],
         "correctChoice":"Btp"
      },
      {
         "title":"Qual è il principale vantaggio delle obbligazioni?",
         "choices":[
            "Hanno effetto leva sugli altri investimenti",
            "Offrono rendimento certo",
            "Garantiscono grandi margini sul capital gain",
            "I loro flussi di interesse sono prevedibili "
         ],
         "correctChoice":"I loro flussi di interesse sono prevedibili "
      },
      {
         "title":"Qual è il principale rischio delle obbligazioni?",
         "choices":[
            "Insolvenza da parte del debitore",
            "Insolvenza da parte del creditore",
            "Diritti esercitati dal debitore",
            "Diritti esercitati dal creditore"
         ],
         "correctChoice":"Insolvenza da parte del debitore"
      }
    ],
    2 : [
      {
         "title":"Cosa indica il valore nominale di un’obbligazione?",
         "choices":[
            "Indica l'importo su cui vengono calcolati gli interessi al momento dello stacco della cedola.",
            "Indica l'importo su cui vengono calcolati gli interessi al momento di un’eventuale buyback di azioni",
            "Indica l'importo su cui vengono calcolati gli interessi al momento dell’emissione dell’obbligazione",
            "Indica l'importo su cui vengono calcolati gli interessi al momento del default del sottostante"
         ],
         "correctChoice":"Indica l'importo su cui vengono calcolati gli interessi al momento dello stacco della cedola."
      },
      {
         "title":"Il rischio valuta ha un impatto sulle obbligazioni?",
         "choices":[
            "Si",
            "No",
            "Si, ma solo se la valuta è il dollaro",
            "No, ma solo se la valuta è l’euro"
         ],
         "correctChoice":"Si"
      },
      {
         "title":"Negli anni ‘90, per far fronte alla discontinuità degli interessi vennero inventate:",
         "choices":[
            "Le obbligazioni callable",
            "Le obbligazioni putable",
            "Le obbligazioni a cedola variabile",
            "Le obbligazioni a cedola fissa"
         ],
         "correctChoice":"Le obbligazioni a cedola variabile"
      },
      {
         "title":"Le obbligazioni sono sempre state usate nei portafogli insieme alle azioni, perché?",
         "choices":[
            "Sono strettamente correlate",
            "Hanno correlazione inversa",
            "Aumentano l’effetto leva",
            "Permettono un ROI generalmente più alto"
         ],
         "correctChoice":"Hanno correlazione inversa"
      },
      {
         "title":"Cos’è il rating di un obbligazione?",
         "choices":[
            "Una misura per valutare il ritorno sul capitale investito",
            "Un indicatore per misurare il livello di interesse di un’obbligazione",
            "Una misura per valutare la qualità dell’emittente",
            "Un indicatore per misurare il numero di obbligazioni presenti sul mercato"
         ],
         "correctChoice":"Una misura per valutare la qualità dell’emittente"
      },
      {
         "title":"Quale tra queste obbligazioni ha rating più alto?",
         "choices":[
            "Obbligazione AA+",
            "Obbligazione AA-",
            "Obbligazione BBB",
            "Obbligazione AAA"
         ],
         "correctChoice":"Obbligazione AAA"
      }
    ],
    3 : [
      {
         "title":"Quale tra le seguenti affermazioni sulle obbligazioni è vera?",
         "choices":[
            "Non pagano mai le cedole",
            "Sono più rischiose delle azioni",
            "Se emesse da una società quotata non danno diritto di voto alle assemblee dei soci",
            "Se emesse da una società quotata partono in sconto del 10%"
         ],
         "correctChoice":"Se emesse da una società quotata non danno diritto di voto alle assemblee dei soci"
      },
      {
         "title":"Quando la curva dei rendimenti si inverte è perchè abbiamo un clima di:",
         "choices":[
            "Incertezza",
            "Euforia",
            "Attendismo",
            "Positività sul lungo termine"
         ],
         "correctChoice":"Incertezza"
      },
      {
         "title":"Le obbligazioni callable sono:",
         "choices":[
            "Obbligazioni che danno il diritto di rimborso anticipato all’emittente",
            "Obbligazioni che danno l’obbligo di rimborso anticipato all’emittente",
            "Obbligazioni che danno il diritto di rimborso anticipato al creditore",
            "Obbligazioni che danno l’obbligo di rimborso anticipato al creditore"
         ],
         "correctChoice":"Obbligazioni che danno il diritto di rimborso anticipato all’emittente"
      },
      {
         "title":"Le obbligazioni putable sono:",
         "choices":[
            "Obbligazioni che danno il diritto di rimborso anticipato all’emittente",
            "Obbligazioni che danno l’obbligo di rimborso anticipato all’emittente",
            "Obbligazioni che danno il diritto di rimborso anticipato al creditore",
            "Obbligazioni che danno l’obbligo di rimborso anticipato al creditore"
         ],
         "correctChoice":"Obbligazioni che danno il diritto di rimborso anticipato al creditore"
      },
      {
         "title":"Uno dei principali problemi degli investimenti obbligazionari è:",
         "choices":[
            "La presenza di ETF obbligazionari",
            "Il lottaggio di alcune obbligazioni",
            "La presenza di derivati sempre più complessi",
            "Il tasso di insolvenza sempre crescente da parte degli emittenti"
         ],
         "correctChoice":"Il lottaggio di alcune obbligazioni"
      },
      {
         "title":"Chi nomina il rappresentante comune degli obbligazionisti?",
         "choices":[
            "Dal consiglio di amministrazione",
            "Dall’assemblea straordinaria",
            "Dai sindacati",
            "Dall'assemblea degli obbligazionisti "
         ],
         "correctChoice":"Dall'assemblea degli obbligazionisti "
      }
    ]
  },
  "Crypto" : {
    1 : [
      {
         "title":"Che cos’è una cryptovaluta? ",
         "choices":[
            "Un contenitore di monete digitali",
            "Una moneta digitale",
            "Un investimento sicuro",
            "Un supporto digitale per le banche"
         ],
         "correctChoice":"Una moneta digitale"
      },
      {
         "title":"La criptovaluta più capitalizzata è:",
         "choices":[
            "Bitcoin",
            "Ethereum",
            "Litecoin",
            "ZCash"
         ],
         "correctChoice":"Bitcoin"
      },
      {
         "title":"Il numero massimo di bitcoin che possono essere presenti sul mercato è:",
         "choices":[
            "5 milioni",
            "17 milioni",
            "21 milioni",
            "26 milioni"
         ],
         "correctChoice":"21 milioni"
      },
      {
         "title":"Cosa significa \"Minare un Bitcoin\"?",
         "choices":[
            "Validare blocchi di transizioni ed essere ripagati in Bitcoin",
            "Svolgere complessi calcoli matematici manualmente per essere ripagati",
            "Distruggere un Bitcoin sulla blockchain",
            "Accertarsi che non sia in corso un attacco hacker sul wallet Bitcoin"
         ],
         "correctChoice":"Validare blocchi di transizioni ed essere ripagati in Bitcoin"
      },
      {
         "title":"Cosa si intende per transazioni finanziarie decentralizzate? ",
         "choices":[
            "Un passaggio di dati in rete",
            "Un passaggio di valuta sulla blockchain",
            "Un passaggio di email riguardanti investimenti",
            "Un controllo dei registri da parte della guardia di finanza"
         ],
         "correctChoice":"Un passaggio di valuta sulla blockchain"
      },
      {
         "title":"Chi è Satoshi Nakamoto?",
         "choices":[
            " L’inventore di Bitcoin ed Ethereum",
            " Uno pseudonimo per lo sviluppatore di Bitcoin",
            " Il ministro Cinese che ha introdotto lo Yuan digitale",
            " Il principale giornalista in ambito criptovalute"
         ],
         "correctChoice":" Uno pseudonimo per lo sviluppatore di Bitcoin"
      }
    ],
    2 : [
      {
         "title":"Qual’è stato il valore totale delle fusioni e delle acquisizioni legate alle criptovalute nel 2020?",
         "choices":[
            "$550 milioni",
            "$850 milioni",
            "$1.1 miliardi",
            "$2.2 miliardi"
         ],
         "correctChoice":"$1.1 miliardi"
      },
      {
         "title":"Quale piattaforma di scambio globale ha rivelato l\\'intenzione di lanciare futures bitcoin \"convenienti\"?",
         "choices":[
            "London stock exchange",
            "Inoltre 500",
            "Chicago Mercantile exchange",
            "New york stock exchange"
         ],
         "correctChoice":"Chicago Mercantile exchange"
      },
      {
         "title":"Un'altra banca di Wall Street è saltata sul carro delle criptovalute, offrendo investimenti in bitcoin ai clienti ricchi. Quale?",
         "choices":[
            "Goldman Sachs",
            "Bank of America",
            "Citibank",
            "Jp Morgan Chase"
         ],
         "correctChoice":"Goldman Sachs"
      },
      {
         "title":"Quale di queste affermazioni sulla blockchain è vera?",
         "choices":[
            "Ogni blocco di transizioni contiene le informazioni del blocco precedente",
            "Ogni blocco è a sè stante, per ottimizzare il processo di mining",
            "Ogni due blocchi vi è un intervallo per la sicurezza della blockchain stessa",
            "Non esistono veri e propri blocchi ma bensì sfere"
         ],
         "correctChoice":"Ogni blocco è a sè stante, per ottimizzare il processo di mining"
      },
      {
         "title":"Qual’è stato il primo governo a creare la sua valuta digitale?",
         "choices":[
            "Cina (Yuan)",
            "Europa (Euro)",
            "Stati uniti (Dollaro)",
            "Giappone (Yen)"
         ],
         "correctChoice":"Cina (Yuan)"
      },
      {
         "title":"La dominance di Bitcoin è:",
         "choices":[
            "Quanto, in percentuale, è prevalente Bitcoin nel mercato cripto",
            "Quanto, in percentuale, Bitcoin domina Ethereum",
            "Quanto è affidabile Bitcoin secondo gli investitori",
            "Quanto sta guadagnando Bitcoin nell’ultima settimana"
         ],
         "correctChoice":"Quanto, in percentuale, è prevalente Bitcoin nel mercato cripto"
      }
    ],
    3 : [
      {
         "title":"Quale paese del Medio Oriente ha pubblicato un documento di consultazione che propone la regolamentazione dei token di sicurezza?",
         "choices":[
            "Emirati Arabi Uniti",
            "Qatar ",
            "Arabia Saudita",
            "Iran"
         ],
         "correctChoice":"Emirati Arabi Uniti"
      },
      {
         "title":"Quale piattaforma decentralizzata ha accusato la SEC di vendere illegalmente titoli nel suo ultimo tentativo di reprimere le criptovalute?",
         "choices":[
            "Sushi swap",
            "LBRY",
            "Pancake swap",
            "Kraken"
         ],
         "correctChoice":"LBRY"
      },
      {
         "title":"Molte personalità si sono espresse a sfavore delle criptovalute, tra queste:",
         "choices":[
            "Elon Musk",
            "Tim Cook",
            "Mario Draghi",
            "Giovanni Ferrero"
         ],
         "correctChoice":"Mario Draghi"
      },
      {
         "title":"Quali tra le seguenti affermazione sui token è vera?",
         "choices":[
            "Sono minabili",
            "Sono meno di 5000",
            "Si possono richiedere anche in moneta fisica",
            "Si appoggiano su blockchain già esistenti"
         ],
         "correctChoice":"Si appoggiano su blockchain già esistenti"
      },
      {
         "title":"Cosa indica la sigla PoW?",
         "choices":[
            "Proof of Waiting",
            "Proof of Work",
            "Permission of Waiting",
            "Permission of Work"
         ],
         "correctChoice":"Proof of Work"
      },
      {
         "title":"Che cos’è un hard fork?",
         "choices":[
            "Un hard fork è un importante aggiornamento del protocollo che obbliga tutti gli utenti a passare ad un nuovo software, se desiderano continuare a utilizzare lo stesso software.",
            "Un hard fork è un importante aggiornamento del protocollo che da il diritto a tutti gli utenti di passare a un nuovo software",
            "Un hard fork è un importante deprezzamento di una cryptovaluta.",
            "Un hard fork è un importante aggiornamento del team di sviluppo di una cryptovaluta."
         ],
         "correctChoice":"Un hard fork è un importante aggiornamento del protocollo che obbliga tutti gli utenti a passare ad un nuovo software, se desiderano continuare a utilizzare lo stesso software."
      }
    ]
  },
  "Macroeconomia" : {
    1 : [
      {
         "title":"Cosa studia la macroeconomia?",
         "choices":[
            "L’intervento pubblico dello stato in economia",
            "Il comportamento individuale degli operatori",
            "Le grandezze aggregate del sistema economico",
            "La relazione tra aziende e stato"
         ],
         "correctChoice":"Le grandezze aggregate del sistema economico"
      },
      {
         "title":"Che funzione svolge un modello macroeconomico?",
         "choices":[
            "Rappresenta il funzionamento del sistema economico",
            "Studia la copertura finanziaria della spesa pubblica",
            "Analizza le differenze tra sistemi economici diversi",
            "Esamina i debiti dei vari stati"
         ],
         "correctChoice":"Rappresenta il funzionamento del sistema economico"
      },
      {
         "title":"Un modello macroeconomico è universale?",
         "choices":[
            "Si, vale in ogni paese e contesto storico",
            "No, vale solamente in un determinato paese e contesto storico",
            "Alcuni modelli macroeconomici sono universali, altri no",
            "Sono tutti universali, salvo particolari eccezioni"
         ],
         "correctChoice":"No, vale solamente in un determinato paese e contesto storico"
      },
      {
         "title":"Esiste una teoria macroeconomica unica?",
         "choices":[
            "Si",
            "No",
            "In parte",
            "Diverse correnti hanno diverse opinioni in merito"
         ],
         "correctChoice":"No"
      },
      {
         "title":"Quale tra questi provvedimenti è attuabile dal governo centrale?",
         "choices":[
            "Modifiche della base monetaria",
            "Modifiche del tasso ufficiale di sconto",
            "Modifiche della tassazione",
            "Sono attuabili tutte le precedenti modifiche"
         ],
         "correctChoice":"Modifiche della tassazione"
      },
      {
         "title":"Da quali fasi è composto un ciclo economico?",
         "choices":[
            "Crisi, Depressione, Ripresa, Espansione",
            "Crisi, Espansione , Ripresa, Depressione",
            "Crisi, Ripresa, Volatilità, Inflazione",
            "Crisi, Interventi governativi, Ripresa, Volatilità"
         ],
         "correctChoice":"Crisi, Depressione, Ripresa, Espansione"
      }
    ],
    2 : [
      {
         "title":"Quali tra le seguenti variabili non influenzano la domanda di moneta da parte degli individui?",
         "choices":[
            "Il tasso di interesse",
            "Il tasso di inflazione atteso",
            "Il tasso di inflazione effettivo",
            "Il tasso di disoccupazione"
         ],
         "correctChoice":"Il tasso di disoccupazione"
      },
      {
         "title":"Cos’è il surplus del consumatore?",
         "choices":[
            "Un modo per capire il vantaggio ottenuto dal consumatore nella transazione",
            "La differenza tra ricavi e costi",
            "Il profitto che ricava l’impresa del consumatore",
            "Un modo per capire le preferenze del consumatore"
         ],
         "correctChoice":"Un modo per capire il vantaggio ottenuto dal consumatore nella transazione"
      },
      {
         "title":"Qual è la principale differenza tra il metodo dell’equilibrio economico generale e quello dell'equilibrio parziale?",
         "choices":[
            "Il generale è rappresentabile con il grafico di domanda ed offerta, mentre il parziale no",
            "Il metodo generale include anche le variabili monetarie a differenza di quello parziale in cui i dati sono solo quelli reali",
            "Non c'è una differenza sostanziale tra i due metodi perché ciascuno esprime il modello macroeconomico di riferimento",
            "Il generale evidenzia gli effetti sul sistema economico della modifica di molte variabili, mentre il parziale fa la stessa cosa considerando però la modifica solo di poche variabili"
         ],
         "correctChoice":"Il generale evidenzia gli effetti sul sistema economico della modifica di molte variabili, mentre il parziale fa la stessa cosa considerando però la modifica solo di poche variabili"
      },
      {
         "title":"Cosa esprime l’elasticità della domanda?",
         "choices":[
            "Il rapporto tra prezzo di equilibrio e quantità domandata",
            "La variazione della quantità del bene prodotto in risposta alle politiche di prezzo del venditore",
            "La variazione della quantità domandata in risposta ad una variazione del prezzo",
            "La pendenza della curva di offerta sul mercato"
         ],
         "correctChoice":"La variazione della quantità domandata in risposta ad una variazione del prezzo"
      },
      {
         "title":"Cos’è lo spiazzamento?",
         "choices":[
            "Un aumento consistente dell'offerta di moneta che provoca inflazione",
            "Un evento inatteso nel sistema che non era stato previsto dal modello macroeconomico",
            "L'aumento dei consumi che si sostituiscono alle esportazioni",
            "La riduzione degli investimenti privati per colpa dell'aumento della spesa pubblica"
         ],
         "correctChoice":"La riduzione degli investimenti privati per colpa dell'aumento della spesa pubblica"
      },
      {
         "title":"Un disavanzo di bilancio pubblico implica che:",
         "choices":[
            "In assenza di monetizzazione del debito pubblico, quest’ultimo è destinato ad aumentare",
            "Lo stato incassa di più di quanto spende (inclusi gli interessi sui titoli di stato)",
            "L’economia sta importando più beni di quanti ne esporta",
            "Gli interessi troppo elevati sui titoli di stato stanno causando un default "
         ],
         "correctChoice":""
      }
    ],
    3 : [
      {
         "title":"Quale tra le seguenti affermazioni è vera?",
         "choices":[
            "Nel brevissimo periodo la curva di offerta aggregata è orizzontale",
            "La Banca Centrale può ridurre l’offerta di moneta esclusivamente mediante una vendita di titoli del debito pubblico",
            "Secondo la curva di Phillips, periodi di elevata inflazione sono caratterizzati da elevata disoccupazione",
            "L'inflazione è direttamente proporzionale all’aumento dei tassi di interesse"
         ],
         "correctChoice":"Nel brevissimo periodo la curva di offerta aggregata è orizzontale"
      },
      {
         "title":"L’idea che una variazione dell’offerta di moneta produca una variazione proporzionale del livello dei prezzi è nota nella teoria economica come:",
         "choices":[
            " Teoria del moltiplicatore keynesiano",
            " Teoria quantitativa della moneta",
            " Teoria dell’utilità marginale",
            " Teoria dei giochi"
         ],
         "correctChoice":" Teoria del moltiplicatore keynesiano"
      },
      {
         "title":"L’aumento del tasso di riserva obbligatoria che cosa provoca?",
         "choices":[
            "Un aumento del moltiplicatore della base monetaria",
            "Un aumento dell’offerta di moneta",
            "Una riduzione del moltiplicatore della base monetaria",
            "Un aumento dell’offerta di moneta combinato ad un aumento del moltiplicatore della base monetaria"
         ],
         "correctChoice":"Una riduzione del moltiplicatore della base monetaria"
      },
      {
         "title":"Nel modello reddito spesa con imposte endogene (ovvero che dipendono dal reddito: TY) il moltiplicatore keynesiano è dato da:",
         "choices":[
            "1-c (1-t)",
            "1/(1-c)",
            "1/(1-c+ct)",
            "1+c (1-t)"
         ],
         "correctChoice":"1/(1-c+ct)"
      },
      {
         "title":"Quando si sposta la curva di domanda aggregata nello schema AD-AS?",
         "choices":[
            "Al variare del reddito",
            "Al variare dell’offerta di moneta",
            "Al variare del livello dei prezzi",
            "Al variare del livello dei tassi di interesse"
         ],
         "correctChoice":"Al variare dell’offerta di moneta"
      },
      {
         "title":"Nello schema IS-LM in economia chiusa, se la domanda di moneta palesa un’elevata elasticità rispetto alle variazioni del tasso di interesse allora:",
         "choices":[
            "La politica fiscale è completamente inefficace",
            "Un’aumento dell’offerta di moneta produce un impatto espansivo molto forte sul livello di equilibrio del reddito aggregato",
            "Una riduzione delle imposte produce un forte impatto espansivo sul livello di equilibrio del reddito aggregato",
            "La Banca Centrale dovrebbe intervenire di più"
         ],
         "correctChoice":"Una riduzione delle imposte produce un forte impatto espansivo sul livello di equilibrio del reddito aggregato"
      }
    ]
  }
}

export default questions