import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import questions from "../../../data/questions"
import randomInteger from 'random-int';
import { Col } from 'react-bootstrap';
import FormLabel from '@mui/material/FormLabel';

function QuestionsSection(props) {
  let changeSection = props.changeSection
  let setFinalResult = props.setFinalResult
  let fields = props.fields
  let level = props.level

  const MAX_QUESTION = 6
  const [selectedQuestions, setSelectedQuestions] = useState([])
  const [answers, setAnswers] = useState({})

  const [currentSection, setCurrentSection] = useState(0)

  function loadQuestions() {
    let list = []
    let i = 0

    if(fields.length != 0 && level != -1) {
      while(list.length != MAX_QUESTION) {
        let field = fields[i % fields.length]
        let possibleQuestion = questions[field][level]
        let index = randomInteger(0, 5)
        if(!list.includes(possibleQuestion[index])) list.push(possibleQuestion[index])
        i++
      }
    }

    return list
  }

  function checkCorrectness() {
    let counter = 0

    for(let question of Object.keys(answers)) {
      let userAnswer = answers[question]
      let [currentQuestion] = selectedQuestions.filter(item => item['title'] == question)
      if(userAnswer == currentQuestion['correctChoice']) counter++
    }

    setFinalResult(counter)
    changeSection()
  }

  useEffect(() => {
    setSelectedQuestions(loadQuestions())
  }, [fields, level])

  useEffect(() => {
    if(currentSection == MAX_QUESTION) checkCorrectness()
  }, [currentSection])
  

  return (
    <Col md="6" className="mx-auto">
    {
      selectedQuestions[currentSection] != undefined ?
      <>
        <h4 style={{ textAlign: "left"}}>{currentSection + 1}) {selectedQuestions[currentSection]['title']}</h4>
        <FormControl>
          <RadioGroup onChange={(e, value) => setAnswers({...answers, [selectedQuestions[currentSection]['title']] : value })} className="text-left" style={{ textAlign: "left"}}>
            {
              selectedQuestions[currentSection]['choices'].map(choice => (
                  <FormControlLabel value={choice} control={<Radio />} label={choice} />
                  ))
              }
          </RadioGroup>
          <br/>
          <br/>
        </FormControl>
        <div className="navigator">
          {
            currentSection >= 0 ?
              <h6 id="previous" onClick={() => setCurrentSection(currentSection - 1)}>Precedente</h6> :
              ""
          }
          {
            currentSection < MAX_QUESTION ?
              <h6 id="next" onClick={() => setCurrentSection(currentSection + 1)}>
                {
                  currentSection == MAX_QUESTION - 1 ?
                    "Fine" :
                    "Successivo"
                }
              </h6> :
              ""
          }
        </div>
      </> : 
      ""
    }
      <br/>
      <br/>
    </Col>
  )
}

QuestionsSection.propTypes = {
  changeSection: PropTypes.func.isRequired,
  setFinalResult: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  level: PropTypes.number.isRequired,
  finalResult: PropTypes.number.isRequired
}

export default QuestionsSection