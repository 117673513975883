let messages = {
  perfect : {
    title : "CINTURA BLU DELLA FINANZA: OTTIMO LAVORO!",
    content : "Complimenti, sei quasi un sensei della finanza! \nLa tua conoscenza della materia è notevole e sai come difendere i tuoi risparmi in molte situazioni. \nMa la cintura blu non è abbastanza: Non vorrai fermarti qui, vero? Le battaglie da vincere sono tante e sempre più difficili! \nScarica l’App Finanz e conquista la cintura nera della finanza!"
  },
  strong: {
    title : "CINTURA VERDE DELLA FINANZA: PUOI FARE DI MEGLIO!",
    content : "Il tuo livello di conoscenza ti permette forse di superare le questioni più semplici, ma quando si tratta di affrontare temi finanziari più complessi sei ancora in difficoltà. \nHai tanto da imparare! \nContinua a documentarti e studia con Finanz. \nL’App gratuita dove formazione e divertimento sono assicurati!"
  },
  medium: {
    title : "CINTURA GIALLA DELLA FINANZA: DEVI MIGLIORARE!",
    content : "Qualcosina sapevi già (o hai tirato ad indovinare durante il Test) però la tua conoscenza in materia finanziaria è ancora bassa e rischia di portarti a fare scelte molto sbagliate. \nAttenzione a non mettere a rischio i tuoi soldi! \nScarica l’App Finanz per imparare gratuitamente ciò di cui hai bisogno per proteggere i tuoi risparmi."
  },
  low: {
    title: "CINTURA BIANCA DELLA FINANZA: NON CI SIAMO!",
    content: "E' chiaro: sai poco o nulla di finanza e non hai mai pensato a come gestire il tuo denaro! \nHai di fronte a te molta strada prima di poterti destreggiare con sicurezza nel mondo dell’economia, ma \nricorda che per essere autonomi non è necessario diventare dei super esperti. Inizia subito a compiere le scelte più giuste per te: scarica l’App Finanz!\nImparerai la finanza gratuitamente, divertendoti ed in soli 5 minuti al giorno."
  }
}

export default messages