import { Collapse } from '@mui/material'
import React, {useState} from 'react'
import IntroductionSection from './sections/introduction'
import QuestionsSection from './sections/questions'
import ResultSection from './sections/result'
import Logo from "../../media/logo.png"
import "./style.css"

function QuizPage() {
  const [level, setLevel] = useState(-1)
  const [fields, setFields] = useState([])
  const [section, setSection] = useState(1)
  const [finalResult, setFinalResult] = useState(0)

  function changeSection() {
    setSection(section + 1)
  }

  return (
    <div id="content">
      <div className="centered full-height">
          <img className="mx-auto logo" src={Logo} />
          <br/>
          <h1 className="">Quiz</h1>
          <div className="separator mx-auto"></div>
          <h5 className="mt-3">Finanz</h5>
        <Collapse in={section == 1} collapsedSize={0}>
            <IntroductionSection
            level={level}
            setLevel={setLevel}
            fields={fields}
            setFields={setFields}
            changeSection={changeSection}/>
        </Collapse>
        <br/>
        <Collapse in={section == 2}>
          <QuestionsSection changeSection={changeSection} setFinalResult={setFinalResult} finalResult={finalResult} level={level} fields={fields} />
        </Collapse>
        <Collapse in={section == 3}>
          <ResultSection finalResult={finalResult} />
        </Collapse>
      </div>

    </div>
  )
}


export default QuizPage