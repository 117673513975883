import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import $ from "jquery"
import messages from '../../../data/messages';
import { Col } from 'react-bootstrap';


function ResultSection(props) {
  const [androidLink, setAndroidLink] = useState("")
  const [iosLink, setIosLink] = useState("")
  const [visible, setVisible] = useState(false)
  let finalResult = props.finalResult
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")

  useEffect(() => {
    if(finalResult <= 2) {
      setTitle(messages['low']['title'])
      setDescription(messages['low']['content'])
    } else if(finalResult > 2 && finalResult <= 4) {
      setTitle(messages['medium']['title'])
      setDescription(messages['medium']['content'])
    } else if (finalResult == 5) {
      setTitle(messages['strong']['title'])
      setDescription(messages['strong']['content'])
    } else if(finalResult == 6) {
      setTitle(messages['perfect']['title'])
      setDescription(messages['perfect']['content'])
    }
  }, [])
  

  useEffect(() => {
    $.ajax({
      url: "https://apifinanz.com/static/application/download_link",
      type: "GET",
      success: (data) => {
        setAndroidLink(data['android'])
        setIosLink(data['ios'])
      }
    })
  }, [])
  

  return (
    <Col md="8" className="block mx-auto">
      <h5 className="mb-3">RISULTATO</h5>
      <div className="separator mx-auto"></div>
      <h1 className="mt-3">{finalResult}/6</h1>
      <h3 className="mt-3">{title}</h3>
      <br/>
      <p className="description">{description}</p>
      <br/>
      <a href="https://onelink.to/finanz-app">
        <button className="m-2" onClick={() => setVisible(true)}>Scarica ora!</button>
      </a>
    </Col>
  )
}

ResultSection.propTypes = {
  finalResult: PropTypes.number.isRequired
}

export default ResultSection